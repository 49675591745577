/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js';

(window as any).global = window;
